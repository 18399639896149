import React from "react";
import { connect } from "react-redux";
import {withRouter} from "react-router-dom";
import { bindActionCreators } from "redux";
import {fetchFilters, updateSSRStatus as updateFilterSSRStatus, clearAllFilters,
    updateSelectedOnScreenFilter, updateFilterOptionData, updateAllSubFilterOptions, updateAppliedFilters,
    updateAllFiltersVisibility,
    fetchListingCount,
    updateUrlOptions,
    updateSelectedSuggestions
} from "../filters/actions";
import { reloadCarList, resetCarList } from "../car-listing/actions";
import PopularCategory from "./component";

const mapStateToProps = ({
    filters: {
        showAllFilters,
        isSSR: isFilterSSR,
        allFilters,
        selectedFilters,
        config,
        listingCount
    },
    homeCarList: {
        totalCars,
        searchPageResult
    },
    cities: {
        selectedCity
    },
    user: {
        isLoggedIn,
        firstName
    }
}) => ({
    showAllFilters,
    isFilterSSR,
    selectedCity,
    selectedFilters,
    allFilters,
    config,
    listingCount,
    totalCars,
    searchPageResult,
    isLoggedIn,
    firstName
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchFiltersConnect: fetchFilters,
    updateFilterSSRStatusConnect: updateFilterSSRStatus,
    clearAllFiltersConnect: clearAllFilters,
    updateSelectedOnScreenFilterConnect: updateSelectedOnScreenFilter,
    reloadCarListConnect: reloadCarList,
    updateFilterOptionDataConnect: updateFilterOptionData,
    updateAllSubFilterOptionsConnect: updateAllSubFilterOptions,
    resetCarListConnect: resetCarList,
    updateAppliedFiltersConnect: updateAppliedFilters,
    updateAllFiltersVisibilityConnect: updateAllFiltersVisibility,
    fetchListingCountConnect: fetchListingCount,
    updateUrlOptionsConnect: updateUrlOptions,
    updateSelectedSuggestionsConnect: updateSelectedSuggestions

}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PopularCategory));

