import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";

const PopularCategoryCard = ({type, carCount, CarImage, onClick}) => {
    return (
        <div onClick={onClick} styleName={"styles.cardOuter"}>
            <p styleName={"styles.heading"}>{type}</p>
            <p styleName={"styles.carCount"}>{carCount}</p>
            <div styleName={"styles.imageWrapper"}>
                <img src={CarImage} />
            </div>
        </div>
    );
};

PopularCategoryCard.propTypes = {
    type: PropTypes.string,
    carCount: PropTypes.string,
    CarImage: PropTypes.string,
    onClick: PropTypes.func
};

export default PopularCategoryCard;
