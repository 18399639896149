import React from "react";
import styles from "./styles.css";
import PopularCategoryCard from "../popular-category-card/component";
import PropTypes from "prop-types";
import { DEFAULT_CITY, FILTER_TYPES } from "../../../constants/app-constants";
import Filters from "../../../utils/filters-v2";
import { trackHomePageEvent } from "../../../handlers/mobile/event-tracker";
import { yieldToMain } from "../../../utils/helpers/yield-to-main";
import useCustomHistoryOperations from "../../../hooks/use-custom-history-operation";

const PopularCategory = ({
    searchPageResult,
    selectedCity,
    allFilters,
    clearAllFiltersConnect,
    updateSelectedSuggestionsConnect,
    updateFilterOptionDataConnect,
    updateAppliedFiltersConnect,
    resetCarListConnect,
    reloadCarListConnect
}) => {
    const { customPush } = useCustomHistoryOperations();
    const {
        popularCategories = {},
        config = {}
    } = searchPageResult;

    const generateData = (data) => {
        const newData = {
            ...data,
            filterKey: data.name,
            optionKey: data.value,
            displayText: data.displayName,
            isSelected: true,
            ...(data.filterType === FILTER_TYPES.RANGE_FILTER && {
                isRangeFilter: true,
                rangeValues: {
                    min: data.min,
                    max: data.max
                }
            }),
            ...(data.subFilterValue && {
                subOptionKey: data.subFilterValue
            })
        };
        return newData;
    };

    const onSelectSuggestion = async (data, displayName) => {
        const newData = [];
        const newFilters = new Filters({cityCode: selectedCity.code || DEFAULT_CITY.AU.code});
        const {relativeURL} = newFilters.getListingPayload({selectedFilters: newData, filters: allFilters});
        await customPush(relativeURL);
        clearAllFiltersConnect();
        data.map(item => {
            const selectedFilterData = generateData(item);
            newData.push(selectedFilterData);
            updateSelectedSuggestionsConnect(selectedFilterData);
            updateFilterOptionDataConnect(selectedFilterData);
            updateAppliedFiltersConnect();
        });
        resetCarListConnect();
        reloadCarListConnect(true);
        await yieldToMain();
        trackHomePageEvent("popular_categories_clicked", `${displayName}`);
    };

    return (
        <div styleName={"styles.outer"}>
            <h2 styleName={"styles.heading"}>Popular Categories</h2>
            <div styleName={"styles.wrapper"}>
                {popularCategories?.result?.categories?.map((category, index) => {
                    return (
                        <PopularCategoryCard
                            key={index}
                            type={category.displayName}
                            carCount={`${category.count} Cars`}
                            CarImage={`${config?.brandImageHost}${category?.desktopImageUrl || category.imageUrl}`}
                            onClick={() => onSelectSuggestion(category.filters, category.displayName)}
                        />
                    );
                })}

            </div>
        </div>
    );
};

PopularCategory.propTypes = {
    searchPageResult: PropTypes.object,
    selectedCity: PropTypes.object,
    allFilters: PropTypes.array,
    clearAllFiltersConnect: PropTypes.func,
    updateSelectedSuggestionsConnect: PropTypes.func,
    updateFilterOptionDataConnect: PropTypes.func,
    updateAppliedFiltersConnect: PropTypes.func,
    resetCarListConnect: PropTypes.func,
    reloadCarListConnect: PropTypes.func
};

export default PopularCategory;
